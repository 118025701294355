<template>
  <div :class="'home ' + (noticeShow ? 'glo-magbot' : 'glo-magbot0')">
    <div class="weather">
      <div class="content">
        <van-row>
            <van-col span="12">
              <i :class="`qi-${weather.icon} weather-icon`"></i>
            </van-col>
            <van-col span="12" class="weather-text">
              <div><span class="tep">{{weather.temp || 0}}°</span> <span>{{weather.text || '多云'}}</span></div>
            </van-col>
        </van-row>
        <van-row>
            <van-col span="8">
                <div><i class="iconfont icon-iconset0469"></i></div>
                <div class="text">{{weather.windSpeed || 0}} km/h</div>
            </van-col>
            <van-col span="8">
                <div><i class="iconfont icon-shidu"></i></div>
                <div class="text">{{weather.humidity || 0}}%</div>
            </van-col>
            <van-col span="8">
                <div><i class="iconfont icon-jiangyuliang"></i></div>
                <div class="text">{{weather.precip || 0}} mm</div>
            </van-col>
        </van-row>
        <div class="update-time">更新时间：{{weather.updateTime}}</div>
      </div>
    </div>  
    <div class="info-data">
      <van-row type="flex" align="center">
        <van-col span="12">
          <div class="block-top block-pub">
            <div class="title">
              <div>
                <van-icon name="desktop-o" />
                <div>监测区</div>
              </div>
            </div>
            <div class="number">{{monitorArea || 0}}</div>
          </div>
        </van-col>
        <van-col span="12">
          <div class="relative chart-content">
            <van-circle v-model="currentRate" layer-color="#e2e2e2" :rate="healthVal || 0" :speed="100" :color="healthVal > 45 ? grad_health : grad_subHealth" :stroke-width="120" size="150px">
                  <div class="glo-inner-align">
                      <div class="time">{{healthVal || 0}}</div>
                      <div class="tag">综合评估</div>
                  </div>
            </van-circle>
          </div>
        </van-col>
      </van-row>
    </div>
    <van-divider :style="{ color: '#2cb5e8', borderColor: '#2cb5e8', padding: '0 16px', margin:'0 0 12px 0' }">智慧桥梁全景AI展示</van-divider>
    <div class="monitor-bridge glo-relative">
      <img :src="pointBgImg">
      <div v-for="(item,index) in moudleData" :key="index" :class="item.status==0 ? 'radar-result-success' : 'radar-result-danger'" :style="{position: 'absolute',top:item.top,left:item.left}" @click="hintInfo(item.modCode)"></div>
    </div>
    <tabbar />
  </div>
</template>

<script>
import tabbar from '@/components/bridge/LHtabbar.vue'

export default {
  name: 'Home',
  components:{
      tabbar
  },
  props: ['magbot'],
  data(){
    return{
        currentRate:0,
        grad_subHealth: {
          '0%': '#DC143C',
          '100%': '#FFD700',
        },
        grad_health:{
          '0%': '#5fe0d4',
          '100%': '#2cb5e8',
        },
        bridgeCode:'',
        healthVal:'',
        magbotVal:'',
        weather:{
          icon:'104',
          updateTime:'2020-01-01T00:00+00:00'
        },
        monitorArea:'',
        areaData:[],
        moudleData:[],
        pointBgImg:null
    }
  },
  // 接收父组件的值
  watch: {
      magbot: function (val) {   
        this.magbotVal = val;   
      }
  },
  methods:{
    // 获取桥梁健康值
    healthInfo(){
      this.$api.BRIDGE.getHealth({
        bridgeCode:this.bridgeCode
      }).then( d=>{
        this.healthVal = d.briHealth *10;
      })
    },
    // 获取天气预报信息
    Weather(){
      this.$api.BRIDGE.getWeather({
        bridgeCode:this.bridgeCode
      }).then( d => {
          if(d.code == 400){return}
          this.weather = {...d.now};
          this.weather.updateTime = d.updateTime
      })
    },
    // 获取实时监控列表
    loadList(){
      this.$api.BRIDGE.realTimeList({
        bridgeCode:this.bridgeCode
      }).then(d=>{
        if(Array.isArray(d)){
            this.areaData = d.filter( e => { return e.xaxisangle});
            this.moduleStatus();
            this.loading = false;
        }
      })
    },
    // 获取模块信息位置
    moduleList(){
      let data ={
        bridgeCode: this.bridgeCode,
        modType: "VIB"
      }
      this.$api.BRIDGE.getModuleInfo(data).then(d=>{
        if(Array.isArray(d)){
            this.moudleData = d;
            this.moudleData.forEach((element,index) => {
              element.status = 0;
              let arr = element.mobileCoordinates.split(',');
              element.top = arr[0];
              element.left = arr[1];
            });
        }
      })
    },
    // 获取模块状态信息
    moduleStatus(){
      let sta = this.areaData.filter( e => e.status==1);
      for(let i=0;i<sta.length;i++){
        this.moudleData.forEach((e,index) => {
          e.modCode == sta[i].devId ? e.status = 1 : void 0;
        })
      }
    },
    // 获取监测区域数量
    devCountInfo(){
      this.$api.BRIDGE.getDevCount({
        bridgeCode:this.bridgeCode
      }).then(d => {
        this.monitorArea = d.vib;
      })
    },
    // 监测桥梁点击显示实时信息
    hintInfo(id){
      if(this.areaData.length == 0 ){
        this.$toast('实时数据获取中，请重试');
        return false;
      }
      let oneself = this.areaData.filter( e => e.devId == id)[0];
      if(oneself.status=='0'){
        this.$notify({
          type:'success',
          message:oneself.showName+': 倾角为'+oneself.xaxisangle+'°，挠度为'+(oneself.vibamp / 1).toFixed(2)+'mm'
        })
      }else{
       this.$notify({
          message:oneself.showName+': 倾角为'+oneself.xaxisangle+'°，挠度为'+(oneself.vibamp / 1).toFixed(2)+'mm'
        })
      }
    },
    // 点位图
    getPointImg(){
      this.$api.NEWBRIDGE.getinodeBitmap({
        projectCode:window.sessionStorage.getItem('projectCode')
      }).then(d=>{
        if(d&&d.length>0){
          this.pointBgImg = d[0].wxPath;
        }
      })
    }
  },
  mounted(){
    this.bridgeCode = window.sessionStorage.getItem('bridgeCode');
    this.getPointImg();
    if(this.bridgeCode){
      this.healthInfo();
      this.Weather();
      this.devCountInfo();
      this.moduleList();
      this.loadList();
    }
  },
  computed: {
    text() {
      return this.currentRate.toFixed(0) + '%';
    },
    noticeShow(){
      return window.sessionStorage.getItem('magbot') == '1' && this.magbot != 1 ? true : false
    }
  },
}
</script>

<style lang="scss" scoped>
.chart-content{
    // padding-top: 8%;
    .time{
      font-size: 2.5rem;
      color: rgb(92, 75, 66);
      margin-bottom: 4%;
      font-weight: bold;
    }
    .tag{color:#a5a5a5;letter-spacing: 2px;font-size: .85rem;}
}
.flex{display: flex;justify-content: left;align-self: center;padding-left: 25%;}
.weather{
  padding: 4% 4% 2% 4%;
  .content{
    padding: 4%;
    background-color: #78baf3;
    background-image: linear-gradient(141deg,#fbfdfc 0%,#2cb5e8 51%,#58bde2 75%);
    border-radius: 15px;
    box-shadow: 3px 1px 5px 0px #b1b1b1;
    .weather-icon{
      font-size: 4rem;
      color: #0089ff;
    }
    .weather-text{
        font-size: .95rem;
        line-height: 2;
        white-space: nowrap;
        .tep{font-size: 2.4rem;font-weight: bold;}
    }
    .iconfont{font-size: 1.5rem;color: #f1f1f1;line-height: 1;}
    .text{font-size: .85rem;margin-top: 4%;color: #f1f1f1;}
    .update-time{font-size: .5rem;color: #5888ab;text-align: right;padding-top: 4%;}
  }
}

.info-data{
  padding:4%;
  .block-pub{
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-self: center;
    padding: 12px;
    border-radius: 10px;
    box-shadow: 2px 2px 2px #ccc;
  }
  .block-top{
    background-color: #39cfe8;
    height: 8rem;
    margin-right: 20px;
    .van-icon{font-size: 2rem;}
    .title{font-size: .75rem;display: flex;align-items: center;}
    .number{font-size: 3rem;padding-left: 4%;display: flex;align-items: center;}
  }
  .margin-space{margin: 6% 0 2% 0;}
}
// 桥梁结构动画
.monitor-bridge{
  padding: 0 4%;
  img{
    border-radius: 10px;
    width: 100%;
    height: 200px;
    background-color: #ccc;
  }
  .radar-result-success {
    background: radial-gradient(rgba(32, 255, 77, 0.3) 0%, rgba(32, 255, 77, 0) 75%), repeating-radial-gradient(rgba(32, 255, 77, 0) 5.8%, rgba(32, 255, 77, 0) 18%, #20ff4d 24.6%, rgba(32, 255, 77, 0) 18.9%), linear-gradient(90deg, rgba(32, 255, 77, 0) 49.5%, #20ff4d 48%, #0dff3f 53%, rgba(32, 255, 77, 0) 50.2%), linear-gradient(0deg, rgba(32, 255, 77, 0) 49.5%, #20ff4d 49%, #20ff4d 52%, rgba(32, 255, 77, 0) 50.2%);
    width: 1.5rem;
    height: 1.5rem;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    border: 1px solid #20ff4d;
    overflow: hidden;
  }
  .radar-result-danger {
    background: radial-gradient(rgba(255, 33, 33, 0.3) 0%, rgba(32, 255, 77, 0) 75%), repeating-radial-gradient(rgba(32, 255, 77, 0) 5.8%, rgba(32, 255, 77, 0) 18%, #df2929 24.6%, rgba(32, 255, 77, 0) 18.9%), linear-gradient(90deg, rgba(32, 255, 77, 0) 49.5%, #df2929 48%, #df2929 53%, rgba(32, 255, 77, 0) 50.2%), linear-gradient(0deg, rgba(32, 255, 77, 0) 49.5%, #df2929 49%, #df2929 52%, rgba(32, 255, 77, 0) 50.2%);
    width: 1.5rem;
    height: 1.5rem;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    border: 1px solid #df2929;
    overflow: hidden;
  }
  .radar-result-success:after{
    content: ' ';
    display: block;
    background-image: linear-gradient(44deg, rgba(0, 255, 51, 0) 50%, #00ff33 100%);
    width: 50%;
    height: 50%;
    position: absolute;
    top: 0;
    left: 0;
    animation: radar-beam 5s infinite;
    animation-timing-function: linear;
    transform-origin: bottom right;
    border-radius: 100% 0 0 0;
  }
  .radar-result-danger:after{
    content: ' ';
    display: block;
    background-image: linear-gradient(44deg, rgba(0, 255, 51, 0) 50%, #df2929 100%);
    width: 50%;
    height: 50%;
    position: absolute;
    top: 0;
    left: 0;
    animation: radar-beam 5s infinite;
    animation-timing-function: linear;
    transform-origin: bottom right;
    border-radius: 100% 0 0 0;
  }
  @keyframes radar-beam {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes blips {
    14% {
      background: radial-gradient(2vmin circle at 75% 70%, #ffffff 10%, #20ff4d 30%, rgba(255, 255, 255, 0) 100%);
    }
    14.0002% {
      background: radial-gradient(2vmin circle at 75% 70%, #ffffff 10%, #20ff4d 30%, rgba(255, 255, 255, 0) 100%), radial-gradient(2vmin circle at 63% 72%, #ffffff 10%, #20ff4d 30%, rgba(255, 255, 255, 0) 100%);
    }
    25% {
      background: radial-gradient(2vmin circle at 75% 70%, #ffffff 10%, #20ff4d 30%, rgba(255, 255, 255, 0) 100%), radial-gradient(2vmin circle at 63% 72%, #ffffff 10%, #20ff4d 30%, rgba(255, 255, 255, 0) 100%), radial-gradient(2vmin circle at 56% 86%, #ffffff 10%, #20ff4d 30%, rgba(255, 255, 255, 0) 100%);
    }
    26% {
      background: radial-gradient(2vmin circle at 75% 70%, #ffffff 10%, #20ff4d 30%, rgba(255, 255, 255, 0) 100%), radial-gradient(2vmin circle at 63% 72%, #ffffff 10%, #20ff4d 30%, rgba(255, 255, 255, 0) 100%), radial-gradient(2vmin circle at 56% 86%, #ffffff 10%, #20ff4d 30%, rgba(255, 255, 255, 0) 100%);
      opacity: 1;
    }
    100% {
      background: radial-gradient(2vmin circle at 75% 70%, #ffffff 10%, #20ff4d 30%, rgba(255, 255, 255, 0) 100%), radial-gradient(2vmin circle at 63% 72%, #ffffff 10%, #20ff4d 30%, rgba(255, 255, 255, 0) 100%), radial-gradient(2vmin circle at 56% 86%, #ffffff 10%, #20ff4d 30%, rgba(255, 255, 255, 0) 100%);
      opacity: 0;
    }
  }
}
</style>